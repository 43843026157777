@import "../scss/icons.scss";
@import "../scss/config/galaxy/bootstrap.scss";
@import "../scss/config/galaxy/app.scss";
@import "../scss/config/galaxy/custom.scss";
@import"./shepherd.css";
@import "./mermaid.min.css";
@import "./calendar.scss";
@import "./tables.scss";

//RTL
// @import "../scss/rtl/components-rtl";
// @import "../scss/rtl/layouts-rtl";
// @import "../scss/rtl/plugins-rtl";
// @import "../scss/rtl/pages-rtl";


//Rigthsidebar offcanvas
.offcanvas-header-dark {
  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

body {
  * {
    outline: none;
  }
}

.noUi-horizontal .noUi-handle {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
  right: -10px !important;
  top: -7px !important;
}

.noUi-connect,
.noUi-horizontal {
  height: 2px !important;
}

.noUi-handle {
  background: #8c68cd !important;
  border: 2px solid var(--vz-card-bg) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.noUi-target {
  background: #fafafa !important;
  border-radius: 4px !important;
  border: 1px solid #d3d3d3 !important;
  background-color: var(--vz-light) !important;
  border-color: var(--vz-light) !important;
  box-shadow: none !important;
  height: 4px !important;
}

.dropzone .dz-message {
  text-align: center;
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;

    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }

  .fc {
    .fc-toolbar {
      >* {
        >* {
          float: none;
        }
      }
    }
  }

  .fc-today-button {
    display: none;
  }
}

.flatpickr-calendar.open {
  z-index: 1090 !important;
}

.upcoming-scheduled .flatpickr-input[readonly] {
  display: none;
}

.ql-container.ql-snow {
  height: 250px;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #405189 !important;
}

// .Toastify__toast {
//   min-height: 43px !important;
//   max-height: 43px !important;
// }


@each $color,
$value in $theme-colors {
  .simplebar-track-#{$color} {
    .simplebar-scrollbar:before {
      background: $value !important;
    }
  }
}

chat .chat-conversation .chat-list .message-box-drop {
  .nav-btn {
    font-size: 18px;
    padding: 4px;
    color: $gray-600;
  }
}

.chatwidgets {
  height: 250px !important;
}

[data-layout="horizontal"] .chat-conversation {
  &.chatwidgets {
    height: 250px !important;
  }
}

// authentication

#tsparticles {
  canvas {
    position: absolute !important;
  }
}

.alert-msg-show {
  right: 24px;
  top: 40px;
  z-index: 1005;
}

// Emoji Picker

.pickerEmoji {
  position: absolute;
  bottom: -50px;
  left: 10%;
  transform: translateX(-50%);
}

.Toastify {
  .Toastify__toast {
    min-height: 43px;
  }

  .Toastify__close-button {
    top: 6px;
  }

  .Toastify__close-button--light {
    color: $light;
  }
}

// Tranfer List

.rdl-filter,
.rdl-control {
  background-color: $input-bg;
  border: 1px solid $input-border-color !important;
  color: $input-color !important;
}

.rdl-move {
  background: var(--#{$prefix}light) !important;
  border: 1px solid var(--#{$prefix}light) !important;
  color: var(--#{$prefix}body-color) !important;
}

.react-dual-listbox {

  input:disabled,
  select:disabled {
    background-color: $input-disabled-bg !important;
    border: 1px solid $input-border-color !important;
  }
}

.css-26l3qy-menu {
  background-color: var(--#{$prefix}gray-200) !important;
  border-color: var(--#{$prefix}border-color) !important;
}

.css-1n7v3ny-option {
  background-color: var(--#{$prefix}gray-300) !important;
  border-color: var(--#{$prefix}border-color) !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--#{$prefix}input-bg) inset !important;
  background-color: var(--#{$prefix}input-bg) !important;
  color: var(--#{$prefix}body-color) !important;
  -webkit-text-fill-color: var(--#{$prefix}body-color) !important;
}

.ml-5 {
	margin-left: 5px;
}

.float-right {
	float: right;
}

.btn-no-style {
	background: none;
	border: none;
	padding: 0;
	color: inherit;
	cursor: pointer;
}

.btn-link {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}

.bg-placeholder {
	.fc-event-main {
		background-color: #f5f5f5;
		color: black;
	}
}

.fc-event {
	cursor: pointer;
}

.justify-content-between{
	justify-content: space-between;
}

.custom-padding{
	padding: 0px 7px;
}

.loading {
		// centered
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
		// background
		background: rgba(255, 255, 255, 0.8);
}

.flex {
		display: flex;
}

.hover-bg:hover {
		background-color: #f5f5f5;
}

.unpaid-list-in-modal {
		max-height: 339px;
		overflow-y: auto;
}

.input-invalid {
		border-color: #dc3545;
}

.mr-3 {
		margin-right: 1rem;
}

.bg-pale-blue {
		background-color: #44accd;
}

.bg-pale-danger {
		background-color: #ff96a0
}

.bg-pale-danger .text-muted {
		color: #495057!important;
}

.datepicker-selected-date {
	background-color: #44accd;
	border-radius: 5px;
	color: #fff;
}

.datepicker-current-date {
	border: 1px solid #44accd;
	border-radius: 5px;
}

.datepicker-disabled-date {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.timepicker-time {
	text-align: center;
	padding: 0.3rem;
	border-radius: 5px;
}

.timepicker-time:hover {
	background-color: #f5f5f5;
}

.timepicker-time.selected{
	background-color: #44accd;
	color: #fff;
}

.rrule-day {
	border-radius: 5px;
	margin: 0 0.2rem;
	text-align: center;
	padding: 0.3rem;
	margin-left: 10px;
	cursor: pointer;
	background-color: #f5f5f5;
}
.selected-day {
	background-color: #44accd;
	color: #fff;
}

.flatpickr-input[disabled] {
	background-color: #f5f5f5;
}

.strikethrough {
	text-decoration: line-through;
}

.hidden {
	visibility: hidden;
}

.justify-center {
	justify-content: center;
}

.p-relative {
	position: relative;
}

input[readonly] {
	background-color: #f5f5f5;
}

input[readonly]:focus {
	background-color: #f5f5f5;
}

.btn-md {
	padding: 0.375rem 0.75rem;
}

.attendee-list {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  width: 100%;
  max-width: 600px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.attendee-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.attendee-item:last-child {
  border-bottom: none;
}

.remove-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
}

.remove-button:hover {
  background-color: #ff1a1a;
}

.switch-sky-event {
	background-color: #7dd3fc!important;
	border-color: #7dd3fc!important;
}

.switch-green-event {
	background-color: #86efac!important;
	border-color: #86efac!important;
}

.switch-red-event {
	background-color: #fca5a5!important;
	border-color: #fca5a5!important;
}

.switch-yellow-event {
	background-color: #fde047!important;
	border-color: #fde047!important;
}

.hover-gray {
	padding: 0.2rem 0.5rem;
}

.hover-gray:hover {
	background-color: #f5f5f5;
}

.align-center {
	align-items: center;
}

.w-100px {
	width: 100px;
}

.border-card {
	margin-top: 10px;
	border: 1px solid #dee2e6;
	border-radius: 5px;
	margin-bottom: 5px;
	padding: 5px 0px;
}

.pricing-model-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 15px;
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #dee2e6;

}
.pricing-model-container.selected {
	background-color: #f5f5f5;
}

.pricing-model-container:hover {
	background-color: #f5f5f5;
}

.bg-gray {
	background-color: #495057;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.pointer {
	cursor: pointer;
}

.text-right {
	text-align: right;
}

.flex-bottom {
	display: flex;
	flex-direction: column-reverse!important;
}

.text-left {
	text-align: left!important;
}

.highlight {
	background-color: #f5f5f5;
}

.mr-auto {
	margin-right: auto;
}

.table-info {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	margin-bottom: 10px;
}

.table-info-first-el {
	flex: 1 1 auto;
	min-width: 60px;
}

.table-info-actions {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: flex-end;
	flex: 1 1 auto;
}

@media (max-width: 600px) {
  .table-info {
    flex-direction: column;
  }

  .table-info-actions {
    justify-content: flex-start;
  }
}

.refresh-btn {
	font-size: 25px;
	line-height: 0;
	padding: 6px;
}

.tab-info {
	color: #6c757d;
	font-size: 0.8rem;
}

.mr-4 {
	margin-right: 1.5rem;
}

.mr-2 {
	margin-right: 0.3rem;
}

.hover-bg-dark:hover {
	background-color: red;
}

.bg-red {
	background-color: lightgray;
}

.action-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	line-height: 20px;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.3s;
	padding: 20px;
	color: #000;
	opacity: 0.71;
	border: none;
}

.action-btn i {
	font-size: 20px;
	vertical-align: middle;
}

.action-btn:hover {
	background-color: #e5e5e5;
}

.text-lg {
	font-size: 1.25rem;
}

.text-dark {
	color: #343a40;
}
