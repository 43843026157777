.ag-pinned-right-header {
	width: 0px !important;
	min-width: 0px !important;
}

.ag-pinned-right-cols-container {
	position: absolute!important;
	right: 0px !important;
	pointer-events: none;
}

.ag-pinned-right-cols-container * {
	pointer-events: initial;
}

.ag-pinned-right-cols-container .ag-cell {
	border: none!important;
}

.ag-pinned-right-cols-container .ag-row:not(.ag-row-hover),
.ag-pinned-right-cols-container .ag-row:not(.ag-row-hover) .ag-cell {
	width: 0px!important;
	padding: 0px!important;
}

.ag-theme-quartz {
    /* disable all borders */
    --ag-borders: none;
    /* then add back a border between rows */
}
