.purple-event {
	// 300
	background-color: #bea6ff;
	border-radius: 5px;
	cursor: pointer;

	.fc-event-title {
		// 900
		color: #4b05ad!important;
		font-size: 15px!important;
	}

	&:hover {
		// 400
		background-color: #9f75ff;
	}
}

.sky-event {
	// 300
	background-color: #7dd3fc;
	border-radius: 5px;
	cursor: pointer;

	.fc-event-title {
		// 900
		color: #0c4a6e!important;
		font-size: 15px!important;
	}

	&:hover {
		// 400
		background-color: #38bdf8;
	}
}

.green-event {
	// 300
	background-color: #86efac;
	border-radius: 5px;
	cursor: pointer;

	.fc-event-title {
		// 900
		color: #14532d!important;
		font-size: 15px!important;
	}

	&:hover {
		// 400
		background-color: #4ade80;
	}
}

.yellow-event {
	// 300
	background-color: #fde047;
	border-radius: 5px;
	cursor: pointer;

	.fc-event-title {
		// 900
		color: #713f12!important;
		font-size: 15px!important;
	}

	&:hover {
		// 400
		background-color: #facc15;
	}
}

.red-event {
	// 300
	background-color: #fca5a5;
	border-radius: 5px;
	cursor: pointer;

	.fc-event-title {
		// 900
		color: #7f1d1d!important;
		font-size: 15px!important;
	}

	&:hover {
		// 400
		background-color: #f87171;
	}
}

.fc-event {
	margin: 0!important;
	padding: 0!important;
	text-align: left!important;
}

.event-paid {
	border-left: 5px solid #4caf50!important;
}

.event-partial {
	border-left: 5px solid #e0cb5d!important;
}

.event-unpaid {
	// gray color
	border-left: 5px solid #d8cece!important;
}

.event-indebted {
	border-left: 5px solid #f44336!important;
}
